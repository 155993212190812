import React from 'react';
import Layout from '../components/layout';
import { alternateLinks } from '../data/site-data';
import Hero from '../components/common/hero';
import CtaDeck from '../components/common/cta-deck';

const headMeta = {
  title: 'Имиграция в САЩ | Имиграционен Aдвокат САЩ | Pandev Law',
  description:
    'Искате ли да емигрирате в САЩ? Pandev Law е адвокатска кантора с богат опит в областта на имиграционното право. Свържете се с нас!',
  keywords:
    'immigration law firm USA,immigration lawyers,immigration law services,immigration attorney,immigration legal services',
  alternateLinks,
};

const schema = `{
  "@context": "https://schema.org/",
  "@type": "Product",
  "name": "Имиграция в САЩ",
  "image": "https://www.pandevlaw.com/img/statue-liberty.jpg",
  "description": "Искате ли да емигрирате в САЩ? Pandev Law е адвокатска кантора с богат опит в областта на имиграционното право. Свържете се с нас!",
  "brand": "Pandev Law",
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "0",
    "ratingCount": "246"
  }
}`;

function Bulgarian({ location }) {
  return (
    <Layout headMeta={headMeta} location={location} footerSchema={schema} lang="bg-BG">
      <Hero
        title={<strong>Вашето Пътешествие към Америка Започва Тук</strong>}
        isTitleBgTransparent
        hideContactLink>
        <p className="text-lead mb-5 mb-md-6">
          <strong>Pandev Law</strong> е имиграционна и бизнес правна кантора, посветена на
          предоставянето на висококачествено представителство в сферата на бизнес и
          имиграционното право. Мотото ни &ldquo;Вашето пътешествие към Америка започва
          тук&rdquo; казва всичко.
          <br />
          <br />
          Предоставям имиграционни решения за инвеститори, международни компании, малки
          фирми и физически лица които искат да се преместят в Съединените Щати. Уникално
          позициониран съм, за да мога ефективно да представлявам индивидуални и бизнес
          клиенти в Съединените Щати и по света.
        </p>
      </Hero>

      <div className="container">
        <div className="row mt-5">
          <div className="col-lg-6">
            <p className="text-body">
              Моята имиграционна практика се фокусира върху инвеститорски визи и такива,
              свързани с набирането на работна ръка, по-специално ЕВ-5 инвеститори и Е-2
              инвеститори, Н-1В специализирани работници, и L-1 вътрешнофирмени трансфери.
              Също така обединявам семейства чрез петиции за брак, годеник/годеница и
              други петиции, основаващи се на роднински връзки. Имам доста голям опит със
              зелените карти и процесите, свързани с наемането на работна ръка,
              включително PERM цертификати за труд, EB-2 петиции за национален интерес, и
              EB-1 петиции за много национални управители и работници с изключителни и
              редки способности, включително академици, изследователи, учени, артисти и
              спортисти.
              <br />
              <br />
              Моята бизнес практика се фокусира в съветването на предприемачи, малки и
              средни по размер бизнеси, по въпроси свързани с формиране и управление,
              сливания и придобивания, търговски сделки и финансиране, включително и
              венчър капитал. Редовно съветвам относно международни транзакции и
              представлявам чуждестранни компании, които търсят начин да влязат на
              американския пазар. Също така съдействам и при съгласия за импорт и експорт,
              и съдействам за получаване на облекчение за нарушения на митническото
              законодателство.
            </p>
          </div>
          <div className="col-lg-6">
            <p className="text-body mb-5">
              Преди да основа Pandev Law, работих като съдебен адвокат в Министерството на
              правосъдието на САЩ, Офиса за имиграционни съдебни дела във Вашингтон. Като
              съдебен адвокат, представлявах правителствени служби – включително
              Държавният департамент на САЩ, Службата по митническа и гранична защита на
              САЩ, и Службата по гражданство и имиграция на САЩ – в съдебни процеси пред
              Федерални окръжни и апелативни съдилища из Съединените Щати. Преди това, съм
              държал позиции в Министерството на труда на САЩ, Имиграционния съд в Атланта
              и в Нюйоркския офис на голяма международна правна кантора, имиграционна
              кантора във Вашингтон и най-голямата в света имиграционна кантора.
              <br />
              <br />
              Аз съм от български произход и говоря български свободно. Редовно пътувам до
              България и съм горд с българските си корени. Бих се радвал да Ви съветвам на
              Вашия роден език. В случай, че искате да се свържете с нас бихте могли да ни
              пишете на имейл{' '}
              <a className="link-primary" href="mailto:admin@pandevlaw.com">
                admin@pandevlaw.com
              </a>{' '}
              или да използвате нашата{' '}
              <a className="link-primary" href="/contact/">
                контактна форма
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <CtaDeck
        title="Запазете си консултация с адвокат още днес"
        buttonText="Говорете с адвокат"
      />
    </Layout>
  );
}

export default Bulgarian;
